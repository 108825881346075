<template>
  <div class="content">
    <div class="content-box">
      <span class="content-item"><i class="fas fa-chart-pie-alt"></i></span>
      <span class="content-item">{{ percent }}%</span>
    </div>
    <div
      class="content-box bordered-item"
      :style="{ borderColor: params.color }"
    >
      <span class="content-item">{{ params.value }}</span>
      <i
        :class="`content-item fas ${this.params.icon}`"
        :style="`color: ${this.params.color} !important`"
      />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    percent() {
      var sumRows =
        this.params.data.insertedRows +
        this.params.data.updatedRows +
        this.params.data.errorRows
      var value = sumRows > 0 ? (this.params.value * 100) / sumRows : 0
      return Math.ceil(value).toFixed(0)
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  line-height: 24px;
  margin: 5px 0px;
  height: 30px;

  .content-box {
    display: inline-block;
    height: 100%;
    margin-left: 8px;
    width: 70px;
  }

  .content-item {
    margin-left: 8px;
  }

  .bordered-item {
    border: solid;
    min-width: 80px;
    position: relative;

    i {
      position: absolute;
      right: 5px;
      line-height: 24px;
    }
  }
}
</style>
