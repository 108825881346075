import { api, createCrudService, useModuleOpen } from '@cargill/shared'

const createService = () => {
  const { getApiRef } = useModuleOpen()
  const basePath = `/api/${getApiRef()}importLog`

  const service = createCrudService(basePath, api)

  service.exportResult = async (id) => {
    const url = `${basePath}/${id}/excel`
    try {
      const response = await api.get(url, {
        responseType: 'blob'
      })
      return response.data
    } catch (e) {
      console.log('ERROR', e)
    }
  }
  return service
}

export default createService
