<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="service"
      :metadata="metadata"
      translatePrefix="application.pages"
      @gridReady="onGridReady"
    />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import Vue from 'vue'
import { CrudAction } from '@brain/core'
import ImportStatusRenderer from '../components/import-log/ImportStatusRenderer'
import moment from 'moment'
import { CargillCrudMetaView, useTransactions, helpers } from '@cargill/shared'
import createService from '../api/importLogService'
import _ from 'lodash'
import FileDownload from 'js-file-download'

const servicesPrefix = ['application.pages', 'controlTower.pages']

export default defineComponent({
  name: 'ImportLog',
  components: { CargillCrudMetaView },
  data() {
    return {
      metadata: null,
      service: null
    }
  },
  created() {
    this.service = createService()
    const vm = this

    this.service.getMeta().then((meta) => {
      meta.permissions = [CrudAction.EXPORT]

      meta.actions = [
        {
          title: this.$t('application.pages.importLog.downloadLog'),
          className: 'fas fa-download',
          enable: ({ node }) => {
            return node.data.errorRows > 0
          },
          callback: async ({ node }) => {
            if (node.data.errorRows > 0) {
              const data = await this.service.exportResult(node.data.id)
              const entityName = this.$t(
                'application.pages.' +
                  node.data.entity[0].toLowerCase() +
                  node.data.entity.slice(1)
              )
              FileDownload(data, `${entityName.title}Log.xlsx`)
            }
          }
        }
      ]

      const fieldsById = _.keyBy(meta.fields, (x) => x.id)

      fieldsById.entity.type = 'select'
      fieldsById.entity.valueFormatter = (param) => {
        for (const prefix of servicesPrefix) {
          const entityTranslate = vm.$t(
            `${prefix}.${_.camelCase(param.value)}.title`
          )
          if (!entityTranslate.startsWith(prefix)) {
            return entityTranslate
          }
        }
        return param.value
      }

      fieldsById.insertedRows.cellRendererFramework =
        Vue.extend(ImportStatusRenderer)
      fieldsById.insertedRows.cellRendererParams = {
        color: '#89CD4D',
        icon: 'fa-check'
      }
      fieldsById.updatedRows.cellRendererFramework =
        Vue.extend(ImportStatusRenderer)
      fieldsById.updatedRows.cellRendererParams = {
        color: '#558DEE',
        icon: 'fa-sync-alt'
      }
      fieldsById.errorRows.cellRendererFramework =
        Vue.extend(ImportStatusRenderer)
      fieldsById.errorRows.cellRendererParams = {
        color: '#D2354F',
        icon: 'fa-times-octagon'
      }

      this.metadata = meta
    })
  },
  methods: {
    formatDate(date) {
      return moment(
        moment(date).format(this.$t('core.dateFormat')),
        this.$t('core.dateFormat')
      ).toDate()
    },
    getGridComponent() {
      return helpers.findFirstComponent(this, ['BcGrid', 'CgGrid'])
    },
    onGridReady() {
      const gridOptions = this.getGridComponent().aggridOptions
      gridOptions.api.setSortModel([
        { colId: 'onlyDate', sort: 'desc' },
        { colId: 'hour', sort: 'desc' }
      ])
      gridOptions.api.onFilterChanged()
    }
  },
  setup(props, { root }) {
    const { canCreate, canRead, canUpdate, canDelete } = useTransactions()

    // If the user dont have permission to read this page,
    // Send it back to the home screen
    if (!canRead('importLog')) {
      root.$router.push('/')
    }

    return { canCreate, canRead, canUpdate, canDelete }
  }
})
</script>
